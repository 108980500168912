export function inViewport() {
  const animatedElements = document.querySelectorAll(
    '._js_inViewport, .__js_preload, .article__image, .element--inviewport',
  );

  const obsCallback = function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('_js_inViewport')) {
          entry.target.classList.add('__js_onScreen');
        }
        if (entry.target.classList.contains('element--inviewport')) {
          document.querySelector('.audio__wrapper').classList.add('desc--visible');
        }
        if (entry.target.classList.contains('article__image')) {
          entry.target.querySelector('img').classList.add('article__image--visible');
        }
        if (entry.target.classList.contains('__js_preload')) {
          const srcS = entry.target.getAttribute('data-src');
          (entry.target).style.opacity = '0.3';
          entry.target.setAttribute('src', srcS);
          (entry.target).style.opacity = '1';
          entry.target.classList.remove('__js_preload');
          observer.unobserve(entry.target);
        }
      } else if (
        entry.target.classList.contains('_js_inViewport')
            && entry.target.classList.contains('__js_onScreen')
      ) {
        // entry.target.classList.remove('__js_onScreen');
        observer.unobserve(entry.target);
      } else if (entry.target.classList.contains('element--inviewport')) {
        document.querySelector('.audio__wrapper').classList.remove('desc--visible');
      }
    });
  };

  const windowH = `${(window.innerHeight - 100) * -1}px`;

  const observer = new IntersectionObserver(obsCallback, {
    root: null,
    threshold: 0,
    // rootMargin: '-10px',
  });

  animatedElements.forEach((elem) => {
    observer.observe(elem);
  });
}
