//rozwijanie FAQ 

export const faq = function () {
    
    const faqItems = document.getElementsByClassName("section__faq_item");

    for(const faqItem of faqItems) {

        faqItem.addEventListener('click', (event) => {
            faqItem.classList.toggle('__jsActive');
      })

    }
    
}