import { latinize } from './latinize';
console.log('v.1.06');
export const divSelect = function (query, cquery) {
	query = !!query ? query : '.form__select_trigger';
	cquery = !!cquery ? cquery : 'form__chb_list';
	let selects = document.querySelectorAll(query);
	let chboxs = document.querySelectorAll(cquery);

	if (!selects || !chboxs) return false;

	for (let i in [...selects]) {
		selects[i].addEventListener("click", (e) => {
			if (e.target.classList.contains('__active')) {
				e.target.classList.remove('__active')
			} else {
				e.target.classList.add('__active')
			}
		});
	}
	/*
		for (let i in [...chboxs]){
			let   
			chboxs[i].addEventListener("change", (e) => {
				
			});
		}
	*/
}

export const validateForm = function () {
	
	const Form = document.getElementById('registerForm');
	if (!Form) { console.log('Form disabled'); return; }
	
	const submitButton = Form.querySelector('button[type="submit"]');
	
	const LETTERS = new RegExp("[\\w\\u0020-]+");
	window.EMAIL = new RegExp('^(([^<>()[\\]\\.,;:\\s@\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$', "i");
	if (!window.formSchema) window.formSchema = {}

	formSchema = {
		"fname": {
			"required": true,
			"minLength": 2,
			"maxLength": 32,
			"condition": LETTERS,
			"error1": "This field is required"
		},
		"lname": {
			"required": true,
			"minLength": 2,
			"maxLength": 120,
			"condition": LETTERS,
			"error1": "This field is required"
		},
		"email": {
			"required": true,
			"minLength": 2,
			"maxLength": 320,
			"condition": EMAIL,
			"error1": "This field is required"
		},
		"country": {
			"required": true,
			"condition": false,
			"error1": "This field is required"
		},
		"people_involved": {
			"required": true,
			"minLength": 2,
			"maxLength": 320,
			"condition": LETTERS,
			"error1": "This field is required"
		},
		"desc": {
			"required": true,
			"minLength": 2,
			"maxLength": 2000,
			"condition": false,
			"error1": "This field is required"
		},
		"category": {
			"required": true,
			"condition": false,
			"error1": "This field is required"
		},
		"link": {
			"required": true,
			"maxLength": 2000,
			"condition": false,
			"error1": "Too long"
		},
		"files": {
			"required": false,
			"minLength": 0,
			"maxLength": 15,
			"condition": false,
			"error1": "You can upload maximum 15 files"
		},

		"zgoda1": {
			"required": true,
			"condition": false,
			"error1": "This field is required"
		},
		"zgoda2": {
			"required": true,
			"condition": false,
			"error1": "This field is required"
		}
	};

	/* fieldShowValidation
	** Shows validation hints - tick or error message
	** Parameters:
	**   field   - input tag DOM node
	**   state   - ["valid" | "invalid"]
	**   message - error message
	*/
	function fieldShowValidation(field, state, message) {
		state = !state ? 'valid' : state;
		if (state == 'valid') {
			field.classList.add('__jsCorrect');
			field.classList.remove('__jsIncorrect');
			let hint = field.parentNode.querySelector('.__jsHint');
			hint.innerHTML = '';
		} else {
			field.classList.remove('__jsCorrect');
			field.classList.add('__jsIncorrect');
			let hint = field.parentNode.querySelector('.__jsHint');
			hint.innerHTML = message;
		}
	}

	function validateField(field) {
		if (!field || !field.type) return false;
		if (typeof formSchema[field.name] !== "object") {
			console.log("Walidacja: Brak definicji inputa %c" + field.name, 'color: yellow');
			return false;
		}
		const $schema = formSchema[field.name];
		let isValid = true;


		switch (field.type) {
			case "text":
				if (field.value.length < $schema.minLength) isValid = false;
				if (!!$schema.condition && !$schema.condition.test(field.value)) isValid = false;
				break;
			case "textarea":
				if (field.value.length > $schema.minLength && field.value.length <= $schema.maxLength) {
					isValid = true;
				} else isValid = false;
				break;
			case "select-one":
				console.log(field.name, field.type);
				if ($schema.required && field.value != 0) {
					isValid = true;
				} else isValid = false;
				break;
			case "checkbox":
				if ($schema.required && field.checked) {
					isValid = true;
				} else isValid = false;
				break;
			case "email":
				isValid = !!$schema.condition.test(field.value);
				break;

			default:
		}

		const errorMessage = isValid ? '' : ($schema.error1 ? $schema.error1 : 'Error');
		fieldShowValidation(field, isValid ? 'valid' : 'invalid', errorMessage);

		return isValid;

	}

	function initValidation() {
		for (const [name, rules] of Object.entries(formSchema)) {
			if (!Form[name]) {
				console.log('Walidacja: Brak w html inputa %c' + name, 'color: yellow');
				return;
			}
			if (Form[name].minLength) Form[name].minLength = formSchema[name].minLength;
			if (Form[name].maxLength) Form[name].maxLength = formSchema[name].maxLength;

			//add containers for errors
			let cnt = document.createElement('div');
			cnt.classList.add('__jsHint');
			try {
				Form[name].parentNode.appendChild(cnt);
			} catch (e) {
				console.log('E', name);
			}

			//live validation
			Form[name].addEventListener('keydown', (e) => {
				const $schema = formSchema[name];
				if (!name || !Form[name] || !$schema) return;
				//for previous filled containers only
				if (Form[name].classList.contains('__jsCorrect') || Form[name].classList.contains('__jsIncorrect')) {
					validateField(Form[name]);
				}
			});

			//field validation
			Form[name].addEventListener('change', (e) => {
				validateField(e.target);
				e.preventDefault();
			});
		}

	}

	let randomFileName = function () {
		let d = new Date();
		d = d.toISOString().substring(0, 19).replace(/[:T-]/gi, '');
		return d + "-" + String(Math.random().toString(34).substring(2, 32));
	}

	let deleteFile = function (e) {
		let hash = e.target.parentNode.dataset.hash;
		console.log('Delete', hash);
		delete filesTable[hash];
		document.getElementsByClassName('form__uploaded')[0].removeChild(e.target.parentNode);
		return true;
	}

	let filesTable = [];

	document.getElementById('fileInput').addEventListener("change", (e) => {
		let fileList = e.target.files;
		//console.log(fileList);
		if (fileList.length > formSchema.files.maxLength) {
			fieldShowValidation('files', 'invalid', `You can add maximum ${formSchema.files.maxLength} files`);
			return;
		} else {
			document.getElementsByClassName('__upload-loader')[0].style.display = 'flex';

			var data = new FormData();
			data.append('contest_id', 224);

			[...fileList].forEach(file => {
				console.log(file);
				let fext = /.([[a-z0-9]{2,5})$/.exec(file.name)[1];
				let fname = '';
				let fhash = '';
				try {
					fname = latinize(file.name);
					fname = /^(.+)\..+$/.exec(fname)[1];
					fname = fname.substring(0, 32).toLowerCase().replace(/[^a-z0-9]/gi, '');
				} catch (e) { }

				fhash = fname + '-' + randomFileName();

				filesTable[fhash] = {
					name: fname,
					extension: fext,
					originName: file.name
				}

				data.append('file', file, fhash);
			});

			//console.log(filesTable);

			//upload plików
			var xhr = new XMLHttpRequest();
			xhr.open('POST', 'https://contests-upload.wp.pl/v2/fu')
			//xhr.setRequestHeader("Content-Type", "multipart/form-data"); rem because of upload error (400)
			xhr.onload = function () {
				if (xhr.status === 200) { //respondeURL
					let { url, contentType } = JSON.parse(xhr.response);
					for (let h in filesTable) {
						if (url.indexOf(h) > -1) {
							filesTable[h].url = url;
							filesTable[h].type = contentType;
							filesTable[h].status = 'send';

							let fcnt = document.createElement('div');
							fcnt.classList.add('form__uploaded_file');
							fcnt.dataset.hash = h;
							fcnt.dataset.type = contentType;
							fcnt.appendChild(document.createElement('i'));
							fcnt.appendChild(document.createTextNode(filesTable[h].name));
							let fcc = document.createElement('div');
							fcc.classList.add('form__uploaded_file_delete');
							fcc.addEventListener('click', deleteFile);
							fcnt.appendChild(fcc);

							let fct = document.getElementsByClassName('form__uploaded')[0];
							fct.appendChild(fcnt);

						}
					}
				}
				else if (xhr.status === 422) {
					console.log('CORS error');
					document.getElementsByClassName('__upload-loader')[0].style.display = 'none';
				}
				else if (xhr.status === 415) {
					console.log('Upload error, File to large');
					document.getElementsByClassName('__upload-loader')[0].style.display = 'none';
				} else console.log('Upoad error', xhr.statusText);
			}
			xhr.onerror = () => Error('There was a network error.');
			xhr.send(data);
		}
	}, false);


	Form.addEventListener('submit', async (e) => {
		const url = Form.dataset.url;
		if (!url) {
			console.log('Fatal error - no form url');
			return;
		}
		e.preventDefault();
		let isValid = true;
		for (let name in formSchema) {
			if (!validateField(Form[name])) isValid = false;
		}

		if (isValid) {
			let data = new FormData(Form);
			data.delete('files');
			for (let hash in filesTable) {
				data.append('files[]', filesTable[hash].url);
			}
			let response = await fetch(url, {
				method: 'POST',
				body: data
			}).then(response => {
				if (response.status == 200 || response.status == 500) {
					return response.text();
				} 
			})
			.then((responseTxt) => {
				//Komunikat z backendu
				document.querySelector('.section__register').innerHTML = `<div class="form_wrapper_response">${responseTxt}</div>`;
				setTimeout(()=>{
					window.scrollTo({
					  top:
						Math.round(window.scrollY) +
						Math.round(document.querySelector('.section__register').getBoundingClientRect().top) -
						170,
					  left: 0,
					  behavior: 'smooth',
					});
				  }, 10);
			})
			.catch((error) => {
				console.log('error', error)
			})
		}
		return false;
	});

	initValidation();

	submitButton.disabled = false;

}
