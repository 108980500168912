import { project } from './initializeStats';
import { statsArticle } from './statsArticle';
import { inViewport } from './elementsInViewport';
import { linkStats, setStatElementCollector, shareStats } from './stats';
import { menu } from './menu';
import { anchors } from './anchors';

import { sliderSwiper } from './slider';
import { faq } from './faq';
import { winner } from './winners';
import { divSelect, validateForm } from './form';


anchors();
statsArticle();
setStatElementCollector();
linkStats();
inViewport();
shareStats();
menu();
faq();
winner();

divSelect('.form__select_trigger', '.form__chb_list');

if(!!document.getElementById('registerForm')) {
    validateForm();
}

sliderSwiper();


window.addEventListener('beforeunload', project.unloadData.bind(project));

let platform = navigator.platform || navigator.userAgentData.platform;
if (platform.toLowerCase().indexOf('mac') > -1 ) {
    document.body.classList.add("mac");
}