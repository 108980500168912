//rozwijanie winners 

export const winner = function () {

    const winnerDescs = document.getElementsByClassName('section__winners_desc');

    if (winnerDescs) {
        for (const winnerDesc of winnerDescs) {

            if (winnerDesc.querySelector('.section_link')) {



                winnerDesc.querySelector('.section_link').addEventListener('click', (event) => {
                    winnerDesc.classList.toggle('__jsActive');
                    if (winnerDesc.classList.contains('__jsActive')) {
                        winnerDesc.querySelector('.section_link').childNodes[0].textContent = "hide";
                    } else {
                        winnerDesc.querySelector('.section_link').childNodes[0].textContent = "more";
                    }
                })


            }



        }
    }

}