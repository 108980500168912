import { project } from './initializeStats';

export const cScrollPercent = (function () {
  const scale = 100;

  let ts = -1;
  let T = null;
  const checkPosition = function () {
    const hh = document.querySelector('header.header').scrollHeight;
    const vh = document.documentElement.clientHeight;
    if (
      !project.activeSlug
        || !document.querySelector(`[id='${project.activeSlug}'].__js_stats_wrapper`)
    ) return;
    const article = document.querySelector(`[id='${project.activeSlug}'].__js_stats_wrapper`);
    const tt = article.getBoundingClientRect().top;
    const r = (article.scrollHeight + hh) / scale; // ratio

    // Jeżeli nie ma tablicy flag dla tego obiektu to utwórz
    if (T == null) {
      T = {
        consumed: {},
        flags: [],
      };
      // inicjacja tablic flag dla kolejnych części
      // eslint-disable-next-line no-plusplus
      for (let k = 1; k <= scale; k++) {
        T.flags[k] = {};
        T.flags[k].begin = 0;
        T.flags[k].end = 0;
      }
    }

    // eslint-disable-next-line no-plusplus
    for (let l = 1; l <= scale; l++) {
      if (
        !T.flags[l].begin
          && tt < -(l - 1) * r + vh + hh
          && tt >= -(l - 1) * r
      ) T.flags[l].begin = 1;
      if (!T.flags[l].end && tt <= -l * r + vh + hh && tt > -l * r) T.flags[l].end = 1;
    }

    // eslint-disable-next-line no-plusplus
    for (let m = 1; m <= scale; m++) {
      if (!T.consumed[m] && T.flags[m].begin && T.flags[m].end) {
        // staty dla setnych
        T.consumed[m] = 1;
      }
    }
  };
  const getValue = function () {
    if (!T) return 0;
    const prc = Object.keys(T.consumed).length;
    T = null; // resetowanie tablicy przy odczycie
    return prc;
  };

  window.addEventListener('load', () => {
    ts = setInterval(checkPosition, 100);
  });
  return {
    burp: getValue,
    debug() {
      return T && T.consumed ? Object.keys(T.consumed).length : '-';
    },
  };
}());
