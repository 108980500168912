import { statsOnWidgetInteraction } from './stats';

export function sliderSwiper() {
  if (document.querySelectorAll('.swiper__container').length) {
    document.querySelectorAll('.swiper__container').forEach((gallery) => {
      const gallerySlider = gallery.querySelector('.gallery-slider');
      const btnPrev = gallery.querySelector('.swiper-button-prev');
      const btnNext = gallery.querySelector('.swiper-button-next');
      const slidesLength = gallerySlider.querySelectorAll('.swiper-slide').length;
      let realIdx = 0;
      const swiper = new Swiper(gallerySlider, {
        loop: true,
        grabCursor: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        centeredSlides: false,
        spaceBetween: 16,
        breakpoints: {
          // when window width is >= 320px
          0: {
            slidesPerView: 2,
          },
          461: {
            slidesPerView: 2.1,
            spaceBetween: 7,
          },
          640: {
            slidesPerView: 3.5,
          },
        },
        navigation: {
          nextEl: btnNext,
          prevEl: slidesLength !== 5 ? btnPrev : null,
        },
        on: {
          realIndexChange() {
            const eventAction = 'ontouchstart' in document.documentElement ? 'tap' : 'click';
            const evType = this.swipeDirection ? 'swipe' : eventAction;
            const item = this.el.querySelector(
              `[data-swiper-slide-index="${this.realIndex}"]`,
            );
            statsOnWidgetInteraction(
              item,
              item.querySelector('img').getAttribute('src'),
              this.realIndex,
              evType,
            );
            realIdx = this.realIndex;
          },
        },
      });
      if (slidesLength === 5) {
        btnPrev.addEventListener('click', () => {
          if (realIdx === slidesLength - 1) {
            swiper.slideToLoop(realIdx - 1, 200, false);
          } else {
            swiper.slideToLoop(realIdx - 1, 400, false);
          }
        });
      }
    });
  }
}
