import { cScrollPercent } from './cScrollPercent';

class Project {
  constructor() {
    this.isScrollingFlag = false;
    this.scroll100Indicator = 0;
    this.statElementCollector = {};
    this.slidePositionVieved = {};
    this.lastReportedSlug = null;
    this.indT = [];
    this._load();
  }

  _load() {
    let idTS;
    window.addEventListener('scroll', () => {
      this.isScrollingController(true);
      if (idTS) {
        clearTimeout(idTS);
      }
      idTS = setTimeout(() => {
        idTS = null;
        this.isScrollingController(false);
      }, 50);
    });
  }

  getScrollingState() {
    return this.isScrollingFlag;
  }

  isScrollingController(state) {
    this.isScrollingFlag = state;
  }

  unloadData() {
    // console.log('this', this);
    const ac = document.querySelector('article').getAttribute('data-id');
    if (this.indT.length === 4) {
      this.scroll100Indicator = 1;
    } else {
      this.scroll100Indicator = 0;
    }
    const statElementArray = [];

    const { scroll100Indicator } = this;

    for (const pos in this.statElementCollector) {
      statElementArray.push(this.statElementCollector[pos]);
    }
    const cScrollPercentValue = cScrollPercent.burp() || 0;

    try {
      WP.pwp.closePage({
        ga: {
          event: 'unload',
          page: {
            sliderConsumption: statElementArray,
            content: { section: { scroll100Indicator } },
            ac,
            templateType: 'AS',
          },
          content: {
            pageViewCount: 1,
            scrollPercent: '',
            cScrollPercent: cScrollPercentValue,
            cS: cScrollPercentValue,
          },
        },
      });
      // console.log(dataLayer);
    } catch (error) {
      console.error('błąd wpjslib: ', error);
    }
  }
}

export const project = new Project();
