import { sendStats } from './stats';
import { project } from './initializeStats';

export function statsArticle() {
  const scale = 4; // dokładność w 1/scale  dla 4 będzie 25% czyli ćwiartki
  let sTS = -1;
  const T = [];
  const checkPosition = function () {
    const hh = document.querySelector('header.header').scrollHeight; // header height
    const vh = document.documentElement.clientHeight; // viewport height
    const articles = document.querySelectorAll('article');

    Array.prototype.forEach.call(articles, (e, n) => {
      const tt = parseInt(e.getBoundingClientRect().top, 10);
      const bt = parseInt(e.getBoundingClientRect().bottom, 10);
      const g = parseInt(vh - tt - hh, 10); // góra artykułu w viewport
      const d = parseInt(bt - hh, 10); // dół artykułu w viewport
      const r = parseInt((e.scrollHeight + hh) / scale, 10); // ratio
      const statId = e.getAttribute('data-id');

      // Jeżeli nie ma jeszcze tablicy flag to ją utwórz
      if (typeof T[n] === 'undefined') {
        T[n] = [];
        // inicjacja tablic flag dla kolejnych ćwiartek
        // eslint-disable-next-line no-plusplus
        for (let k = 1; k <= scale; k++) {
          T[n][k] = {};
          T[n][k].begin = 0;
          T[n][k].end = 0;
          T[n][k].send = 0;
        }
      }

      // czy artykuł jest w vieportcie
      if (g > 0 && d > 0) {
        if (typeof T[n][0] === 'undefined') {
          T[n][0] = 1;
          // PageViews
          // console.log('view', 'scroll', statId);
          sendStats('view', 'scroll', statId);
        }
      }

      // eslint-disable-next-line no-plusplus
      for (let l = 1; l <= scale; l++) {
        if (
          !T[n][l].begin
            && tt < -(l - 1) * r + vh + hh
            && tt > -(l - 1) * r
        ) T[n][l].begin = 1;
        if (!T[n][l].end && tt < -l * r + vh + hh && tt > -l * r) T[n][l].end = 1;
      }

      // eslint-disable-next-line no-plusplus
      for (let m = 1; m <= scale; m++) {
        if (!T[n][m].send && T[n][m].begin && T[n][m].end) {
          sendStats('progress', 'scroll', statId, m * (100 / scale));
          // console.log('progress', 'scroll', statId, m * (100 / scale));
          // staty dla ćwiartek
          T[n][m].send = 1;
          project.indT.push(n);
        }
      }
    });
  };

  window.addEventListener('load', () => {
    if (sTS > 0) clearTimeout(sTS);
    sTS = setTimeout(checkPosition, 750);
  });

  window.addEventListener('scroll', () => {
    if (sTS > 0) clearTimeout(sTS);
    sTS = setTimeout(checkPosition, 50);
  });
}
